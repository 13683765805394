.cardShine {
  position: relative;
  overflow: hidden;
}

.cardShine::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -60%;
  width: 20%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);

  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );

  transition-property: left, opacity;
  transition-duration: 0.8s, 0.8s;
  transition-timing-function: ease;
}

.cardShine:hover::after {
  opacity: 1;
  left: 150%;
}

.cardShine:active::after {
  opacity: 0;
}
