.animated-video {
  opacity: 0;
  scale: 0.6;

  animation: videoAnimation 1s ease 0.5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@keyframes videoAnimation {
  0% {
    opacity: 0;
    scale: 0.1;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
